import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './auth.service';
import { HealthBannerService } from './healthBanner.service';
import { JobService } from './job.service';
import { DonationOptionsService } from './donationOptions.service';
import { AssessmentService } from './assessment.service';
import { BankNicepayService } from './bankNicepay.service';
import { BankDokuService } from './bankDoku.service';
import { SocialCampaignService } from './socialCampaign.service';
import { EducationService } from './education.service';
import { CategoryService } from './category.service';
import { CategoryTypeService } from './categoryType.service';
import { CatalogMasterService } from './catalogMaster.service';
import { TieringService } from './tiering.service';
import { LifestyleTravelService } from './lifestyleTravel.service';
import { BenefitService } from './benefit.service';
import { SocialCampaignConditionService } from './socialCampaignCondition.service';
import { LifestyleTravelGuideService } from './lifestyleTravelGuide.service';
import { LifestyleTravelDestinationService } from './lifestyleTravelDestination.service';
import { LifestyleTravelTransportationService } from './lifestyleTravelTransportation.service';
import { MenuLifestyleService } from './menuLifestyle.service';
import { MenuSocialService } from './menuSocial.service';
import { MenuHealthService } from './menuHealth.service';
import { StoriesHeaderService } from './storiesHeader.service';
import { StoriesPageService } from './storiesPage.service';
import { SocialInstitutionService } from './socialInstitution.service';
import { LifestyleGoldenClassCatalogService } from './lifestyleGoldenClassCatalog.service';
import { LifestyleScholarClassCatalogService } from './lifestyleScholarClassCatalog.service';
import { LifestyleSponsorService } from './lifestyleSponsor.service';
import { LifestyleLandingHeaderService } from './lifestyleLandingHeader.service';
import { MemberService } from './member.service';
import { MenuService } from './menu.service';
import { RolesService } from './roles.service';
import { RolesMenuAccessService } from './rolesmenuaccess.service';
import { UserService } from './user.service';
import { UploadTestService } from './uploadTest.service';
import { WfbService } from './wfb.service';
import { BPJSVerificationService } from './bpjsverification.service';
import { PushNotifService } from './pushNotif.service';
import { CatalogTieringDiscountService } from './catalogTieringDiscount.service';
import { AuthGuard } from './auth-guard.service';
import { TransactionReportService } from './transactionReport.service';
import { MasterwfbService } from './master-wfb.service';
import { WfblocationService } from './wfbLocation.service';
import { WfbimageService } from './wfbImage.service';
import { TransRefundService } from './transRefund.service';
import { GeneralTransactionsService } from './generalTransactions.service';
import { ProductPartnerTransactionsService } from './productPartnerTransactions.service';
import { TheBridgeService } from './theBridge.service';
import { TheBridgeEpisodeService } from './theBridgeEpisode.service';
import { PaymentStatusService } from './paymentStatus.service';
import { PaymentChannelService } from './paymentChannel.service';
import { StudentVerificationService } from './studentverification.service';
import { ProvinceService } from './province.service';
import { RegenciesService } from './regencies.service';
import { ProvinceCovService } from './province-cov.service';
import { RegenciesCovService } from './regencies-cov.service';
import { ConsultationOperatingHoursService } from './consultationOperatingHours.service';
import { MerchantService } from './merchant.service';
import { VoucherService } from './voucher.service';
import { PackageCatalogService } from './packageCatalog.service';
import { PackageCatalogDetailService } from './packageCatalogDetail.service';
import { PackageCategoryService } from './packageCategory.service';
import { SocialCampaignFoundationService } from './socialCampaignFoundation.service';
import { HealthCovTestPartnerTypeService } from './healthCovTestPartnerType.service';
import { HealthCovTestPartnerService } from './healthCovTestPartner.service';
import { DashboardUserActivityService } from './dashboard-user-activity.service';
import { DashboardTransactionsService } from './dashboard-transactions.service';
import { DashboardHealthService } from './dashboard-health.service';
import { DashboardLifestyleService } from './dashboard-lifestyle.service';
import { PaymentMethodsService } from './paymentMethods.service';
import { TierSubcriptionCatalogService } from './tierSubcriptionCatalog.service';
import { FinanceServiceCatalogService } from './financeServiceCatalog.service';
import { QuickIconService } from './quickicon.service';
import { DynamicBannerService } from './dynamic-banner.service';
import { CountryService } from './country.service';
import { WelcomeScreenService } from './welcomeScreen.service';
import { WelcomeBenefitService } from './welcomeCatalogBenefit.service';
import { UploadFileService } from './uploadFile.service';
import { PaymentSetupService } from './paymentSetup.service';
import { ProductCategoryService } from './productCategory.service';
import { PartnerService } from './partner.service';
import { MasterPartnerService } from './master-partner.service';
import { FaskesInfoService } from './faskesInfo.service';
import { CityService } from './city.service';
import { ProductService } from './product.service';
import { MasterPartnerDetailService } from './master-partner-detail.service';
import { MasterTermAndConditionService } from './master-term-and-condition.service';
import { BpjsBenefitService } from './bpjsBenefit.service';
import { SubscriptionTierService } from './subscription-tier.service';
import { SubscriptionPackageService } from './subscription-package.service';
import { QuestionerService } from './questioner.service';
import { AnswerService } from './answer.service';
import { PaymentTransactionService } from './paymentTransaction.service';
import { PaymentGatewayService } from './paymentGateway.service';
import { MemberVouchersService } from './memberVouchers.service';
import { TLIRiskDataService } from './tliRiskData.service';
import {ProductVoucherService} from './productVoucher.service';
import {UVCMerchantConfigService} from "./uvcMerchantConfig.service";
import {AmanRewardService} from "./amanReward.service";

@NgModule({
  imports: [CommonModule],
  declarations: [],
})
export class ServicesModule {
  static forRoot(): ModuleWithProviders<ServicesModule> {
    return {
      ngModule: ServicesModule,
      providers: [
        AuthService,
        HealthBannerService,
        JobService,
        DonationOptionsService,
        AssessmentService,
        BankNicepayService,
        BankDokuService,
        SocialCampaignService,
        EducationService,
        CategoryService,
        CategoryTypeService,
        CatalogMasterService,
        TieringService,
        QuickIconService,
        DynamicBannerService,
        LifestyleTravelService,
        BenefitService,
        SocialCampaignConditionService,
        LifestyleTravelGuideService,
        LifestyleTravelDestinationService,
        LifestyleTravelTransportationService,
        MenuLifestyleService,
        MenuSocialService,
        MenuHealthService,
        StoriesHeaderService,
        StoriesPageService,
        SocialInstitutionService,
        LifestyleGoldenClassCatalogService,
        LifestyleScholarClassCatalogService,
        LifestyleSponsorService,
        LifestyleLandingHeaderService,
        MemberService,
        MenuService,
        RolesService,
        RolesMenuAccessService,
        UserService,
        UploadTestService,
        WfbService,
        BPJSVerificationService,
        PushNotifService,
        CatalogTieringDiscountService,
        AuthGuard,
        TransactionReportService,
        MasterwfbService,
        WfblocationService,
        WfbimageService,
        TransRefundService,
        GeneralTransactionsService,
        ProductPartnerTransactionsService,
        TheBridgeService,
        TheBridgeEpisodeService,
        PaymentStatusService,
        PaymentChannelService,
        StudentVerificationService,
        ProvinceService,
        RegenciesService,
        ProvinceCovService,
        RegenciesCovService,
        ConsultationOperatingHoursService,
        MerchantService,
        VoucherService,
        PackageCatalogService,
        PackageCatalogDetailService,
        PackageCategoryService,
        SocialCampaignFoundationService,
        HealthCovTestPartnerTypeService,
        HealthCovTestPartnerService,
        DashboardUserActivityService,
        DashboardTransactionsService,
        DashboardHealthService,
        DashboardLifestyleService,
        PaymentMethodsService,
        TierSubcriptionCatalogService,
        FinanceServiceCatalogService,
        CountryService,
        WelcomeScreenService,
        WelcomeBenefitService,
        UploadFileService,
        PaymentSetupService,
        ProductCategoryService,
        PartnerService,
        MasterPartnerService,
        FaskesInfoService,
        CityService,
        ProductService,
        MasterPartnerDetailService,
        MasterTermAndConditionService,
        BpjsBenefitService,
        SubscriptionPackageService,
        SubscriptionTierService,
        QuestionerService,
        AnswerService,
        PaymentTransactionService,
        PaymentGatewayService,
        MemberVouchersService,
        TLIRiskDataService,
        ProductVoucherService,
        UVCMerchantConfigService,
        AmanRewardService,
      ],
    };
  }
}
