import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,  HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { PartnerData } from './partner.service';

export interface ProductCategoryData {
    id: number,
    nameEn: string,
    nameId: string,
    partner: PartnerData,
    status: boolean,
    pillarCategoryCode: string
  }

  export interface ITableData {
    statusCode: number;
    status: string;
    message: string;
    data: {
      content: ProductCategoryData[];
      empty: boolean;
      first: boolean;
      last: boolean;
      number: number;
      numberOfElements: number;
      size: number;
      totalElements: number;
      totalPages: number;
    }
  }
@Injectable()
export class ProductCategoryService {
  private SERVICE_URL = environment.apiUrl;
  private TOKEN = localStorage.getItem('token')

  constructor(
    private http: HttpClient
  ) { }

  public getData() {
    const headers: HttpHeaders = new HttpHeaders({
      'Accept'        : 'application/json',
      'Content-Type'  : 'application/json',
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.get<ITableData>(this.SERVICE_URL + "api/product-category",{headers}).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
            return throwError(error);

    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  public getProductCategories(): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Accept'        : 'application/json',
      'Content-Type'  : 'application/json',
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.get(this.SERVICE_URL + "api/product-category", {headers});
  }

  public saveProductCategory(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Accept'        : 'application/json',
      'Authorization' : 'Bearer '+this.TOKEN,
    });
    return this.http.post(this.SERVICE_URL + "api/product-category", data , {headers});
  }

  public editProductCategory(data: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Accept'        : 'application/json',
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.put(this.SERVICE_URL + `api/product-category/${data.id}`, data , {headers});
  }

  public deleteProductCategory(id: any): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'Accept'        : 'application/json',
      'Authorization' : 'Bearer '+this.TOKEN
    });
    return this.http.delete(this.SERVICE_URL + `api/product-category/${id}`, {headers});
  }


}
